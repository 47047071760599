// layouts
import main from "@/layout/main.vue";
import empty from "@/layout/empty.vue";

// landing
const Home = () => import("@/pages/home/home.vue");

// talent
const talent_home = () => import("@/pages/talent/talent.vue");
const hire_talents = () => import("@/pages/talent/hire-talents.vue");
const join_talents = () => import("@/pages/talent/join-talents.vue");

const routes = [
  {
    path: "/",
    component: main,
    props: true,
    children: [
      // landing
      { name: "home", path: "/", component: Home, meta: { entryPoint: true } },
    ],
  },

  {
    path: "/",
    props: true,
    component: empty,
    children: [
      {
        name: "hire_talents",
        path: "/talent/hire-talents",
        component: hire_talents,
      },
      {
        name: "join_talents",
        path: "/talent/join-talents",
        component: join_talents,
      },
    ],
  },

  {
    path: "/:catchAll(.*)*",

  },
];

export default routes;
