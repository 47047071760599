<template>
  <div>
    <Navigation />
    <router-view />
    <Footer />

    <a href="#">
      <div class="circle-glass go-up">
        <img alt="back to top" src="@/assets/icons/goup.svg" />
      </div>
    </a>
  </div>
</template>


<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    Footer,
  },
  data() {
    return {
      state: "show",
      showAppMenu: false,
      appmenuclass: "hidden",
    };
  },
  methods: {
    toggleMenu() {
      if (this.state != "show") {
        this.state = "show";
      } else {
        this.state = "none";
      }
    },
    toggleAppMenu() {
      if (this.appmenuclass != "display") {
        // this.showAppMenu = true;
        this.appmenuclass = "display";
      } else {
        // this.showAppMenu = false;
        this.appmenuclass = "hidden";
      }
    },
  },
};
</script>

<style scoped>
.display {
  display: block;
  height: auto;
  position: absolute;
  top: 25vh;
  height: 200px;
  left: 1em;
  cursor: pointer;
  position: fixed;
}

.closebtn {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50vh;
  left: 7em;
  cursor: pointer;
  position: fixed;
  background: rgb(233, 50, 50);
  border-radius: var(--border-radius);
  display: grid;
  place-items: center;
  color: white;
}

.hidden {
  display: none;
}

.floatingHome {
  position: absolute;
  color: white;
  width: 60px;
  height: 60px;
  top: 50vh;
  left: 25px;
  position: fixed;
  padding: 15px;
  cursor: pointer;
}

.go-up {
  position: absolute;
  color: white;
  width: 50px;
  bottom: 100px;
  border-radius: var(--border-radius);
  right: 30px;
  position: fixed;
  padding: 15px;
  display: grid;
  place-items: center;
  z-index: 9999;
}

.go-up img {
  width: 13px;
}

@media (max-width: 768px) {
  .go-up {
    position: absolute;
    color: white;
    width: 50px;
    bottom: 80px;
    border-radius: var(--border-radius);
    right: 15px;
    position: fixed;
    padding: 15px;
    display: grid;
    place-items: center;
  }
}
</style>