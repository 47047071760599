<template>
  <main class="navigation">
    <header class="desktop-nav">
      <div class="header__container">
        <div class="header__left">
          <a href="/">
            <img class="logo" src="@/assets/images/logo.png" alt="Logo" />
          </a>
        </div>
        <div class="header__right">
          <nav>
            <ul class="nav__links">
              <li class="dropdown"><a href="https://shop.madeinafrika.com">Shop Now !</a></li>
              <li class="dropdown"><a href="">For Business</a></li>
              <li class="dropdown">
                <a href="https://blog.madeinafrika.com">Blog</a>
              </li>
              <li>
                <a href="/learningpaths" class="nav__cta__secondary">
                  <button>Get Started</button>
                </a>
              </li>
            </ul>
          </nav>
          <a href="#" class="nav__menu" @click="openNav()">
            <img src="@/assets/icons/menu-icon.svg" />
          </a>
        </div>
      </div>
    </header>
    <div id="mobile__menu" class="mobile-nav overlay">
      <a href="#" class="close" @click="closeNav()">&times;</a>
      <div class="overlay__content">
        <ul class="mobile__links">
          <li class="dropdown"><a href="https://shop.madeinafrika.com">Shop</a></li>
          <li class="dropdown"><a href="/business">For Business</a></li>
          <li class="dropdown">
            <a href="https://blog.madeinafrika.com">Blog</a>
          </li>
          <li>
            <a href="/learningpaths" class="nav__cta__secondary">
              <button>Get Started</button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      host: "https://madeinafrika.org",
      app_host: "https://app.madeinafrika.org",
      talent_host: "/talent",
      blog_host: "https://blog.madeinafrika.org",
      kid_host: "https://kids.madeinafrika.org",
    };
  },
  methods: {
    openNav() {
      document.getElementById("mobile__menu").style.width = "100%";
    },

    closeNav() {
      document.getElementById("mobile__menu").style.width = "0";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@media (min-width: 1024px) {
  #mobile__menu {
    display: none;
  }
}

.navigation {
  position: fixed;
  top: 0;
  background-color: #ffffff00;
  z-index: 100;
  border-bottom: 1px solid var(--primary-tint);
}

ul {
  list-style: none;
}

li,
a {
  color: white;
  text-decoration: none;
  font-weight: 200;
}

.header__container {
  display: flex;
  max-width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.logo {
  cursor: pointer;
  width: 60px;
}

.cta {
  padding: 0 20px;
}

.header__right a {
  padding: 10px 5px;
  display: flex;
  font-size: 13px;
  align-items: center;
  margin: 0 10px;
  transition: all 0.1s linear;
}

li.dropdown > a {
  border-bottom: 3px solid transparent;
}

.header__right li.dropdown a:hover {
  border-bottom: 3px solid var(--primary);
}

.header__right div.dropdown__content a:hover {
  border: none;
}

.nav__menu img {
  width: 20px;
}

.header__right {
  display: flex;
  align-items: center;
}

.nav__links {
  display: flex;
  margin: 5px 5px;
  align-items: center;
}

.nav__links a span {
  display: inline-block;
  font-size: 13px;
  margin-left: 4px;
}

.nav__links a span.less {
  display: none;
}

.dropdown__container {
  display: none;
  position: absolute;
  background-color: var(--secondary);
  width: 100%;
  left: 0;
  z-index: 60;
}

.dropdown__content {
  max-width: 1100px;
  margin: 0 auto;
}

.dropdown__container a {
  color: rgb(18, 18, 18);
}

.dropdown:hover a span.less {
  display: block !important;
}

.dropdown:hover a span.more {
  display: none !important;
}

.dropdown:hover .dropdown__container {
  display: block;
}

a.nav__menu {
  display: none;
}

.nav__cta button {
  padding: 10px 20px;
  background-color: var(--primary);
  border: none;
  color: rgb(18, 18, 18);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav__cta button:hover {
  color: rgb(18, 18, 18);
  background-color: var(--primary-tint);
}

.nav__cta__secondary button {
  padding: 10px 20px;
  background-color: var(--primary);
  border: none;
  color: rgb(18, 18, 18);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

/* .mobile-nav .show, */
.mobile-nav input {
  display: none;
}

@media screen and (max-width: 1024px) {
  ul.nav__links,
  a.nav__cta {
    display: none;
  }

  a.nav__menu {
    display: inherit;
  }

  .overlay a {
    font-size: 20px;
  }

  .mobile__links .less {
    display: none;
  }

  .mobile-dropdown__container {
    margin-top: 20px;
    width: 100%;
    left: 0;
    z-index: 60;
  }

  .mobile-dropdown__container a {
    display: block;
  }

  .mobile-nav .show {
    display: block;
    cursor: pointer;
  }

  .mobile-nav .show:hover {
    color: rgba(0, 136, 169, 1);
  }

  .show + a,
  .mobile-dropdown__container {
    display: none;
  }

  [id^="btn"]:checked + .mobile-dropdown__container {
    display: block;
  }
}

/* Mobile Nav */
.overlay {
  min-height: 100%;
  width: 0;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  background-color: #24252a;
  overflow-x: hidden;
  transition: all 0.5s ease;
}

.overlay__content::-webkit-scrollbar {
  display: none;
}

.overlay__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobile__links,
.mobile-cta {
  width: 100%;
}

#mobile__menu > div > ul > li {
  padding: 15px;
  display: block;
}

.mobile-cta .nav__cta {
  display: block;
  padding: 15px;
}

#mobile__menu > div > ul > li > a {
  display: flex;
  align-items: center;
}

.overlay a {
  transition: all 0.2s ease;
}

.overlay a:hover,
.overlay:focus {
  color: #0088a9;
}

.overlay .close {
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 30px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 16px;
  }

  .overlay .close {
    font-size: 30px;
    top: 15px;
    right: 35px;
  }
}

/* learning path */
</style>
